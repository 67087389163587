import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HtmlControl } from 'src/app/shared/models/html-control.model';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.css']
})
export class LabelComponent implements OnInit {

  public Control: HtmlControl;
  public Group: FormGroup;

  constructor() {
  }

  ngOnInit(): void {
  }

}
