import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { ComponentsModule } from './shared/components';
import { UtilityModule } from './shared/utility/utility.module';
import { HomeComponent } from './features-modules/home/home.component';
import { AuthGuard } from './shared/guards/auth.guard';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormModule } from './features-modules/form/form.module';
import { FormsModule } from '@angular/forms';
import { FormsComponent } from './features-modules/forms/forms.component';
import { OrdersComponent } from './features-modules/orders/orders.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material/material.module';
import { OrdersModule } from './features-modules/orders/orders.module';
import { FooterComponent } from './shared/components/footer/footer.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { DynamicFormModule } from './features-modules/initial-form/dynamic-form/dynamic-form.module';
import { InitialFormModule } from './features-modules/initial-form/initial-form.module';
import { LabelComponent } from './shared/components/form-controls/label/label.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FormsComponent,
    OrdersComponent,
    FooterComponent,
    HeaderComponent,
  ],
  imports: [
    AuthModule,
    BrowserModule,
    AppRoutingModule,
    ComponentsModule,
    UtilityModule.forRoot(),
    NgbModule,
    FormModule,
    FormsModule,
    BrowserAnimationsModule,
    InitialFormModule,
    MaterialModule,
    OrdersModule,
    DynamicFormModule
  ],
  providers: [AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
