import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HeaderService } from 'src/app/shared/service/header.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public submitted = false;
  public email: AbstractControl;
  public password: AbstractControl;
  public loginForm: FormGroup;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, public authService: AuthService,
              private headerService: HeaderService) {
    this.headerService.showOrHideHeader.next(false);
  }

  ngOnInit() {
    this.initLoginForm();
  }

  initLoginForm() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, this.authService.validationService.validateEmail]],
      password: ['', Validators.required]
    });

    this.email = this.loginForm.controls.email;
    this.password = this.loginForm.controls.password;
  }




  public onSubmit(event: Event, form: any): void {
    event.stopPropagation();
    this.submitted = true;
    if (this.loginForm.valid) {
      const returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');
      this.authService.autoLogin();
      this.authService.login();
    }
  }
}
