import { FormControls, TechnologyControl } from './../models/form-controlls.model';
import { HtmlControl } from '../models/html-control.model';
import { Validators } from '@angular/forms';

export class FormControlMapper {


  public mapToHtml(technologyControl: TechnologyControl, step: string): HtmlControl {
    let control = new HtmlControl();

    control = {
      inputType: technologyControl.ControlType.toLocaleLowerCase(),
      name: technologyControl.KeyName,
      componentName: technologyControl.ControlType,
      options: technologyControl.Values,
      value: technologyControl.DefaultValue,
      label: technologyControl.Description,
      width: technologyControl.Width,
      currentStep: step,
      isPrint: technologyControl.IsPrint
    };
    console.log('technologyControl', technologyControl);
    if (technologyControl.ControlType === 'Select') {
      control.validations = [ {name: 'required' , validator: Validators.required, message: 'Wybierz opcję'}];
    }

    return control;
  }
}
