import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { HtmlControl } from 'src/app/shared/models/html-control.model';

@Component({
  selector: 'app-check-box',
  templateUrl: './check-box.component.html',
  styleUrls: ['./check-box.component.css']
})
export class CheckBoxComponent implements OnInit {
  public Control: HtmlControl;
  public Group: FormGroup;

  public test = false;

  constructor() { }

  ngOnInit(): void {
  }

}
