import { ComponentsModule } from './../../shared/components/index';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrdersRoutingModule } from './orders-routing.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    OrdersRoutingModule,
    ComponentsModule
  ]
})
export class OrdersModule { }
