import { FormService } from './../../shared/service/form.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpRequest, HttpEvent, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { AuthService } from 'src/app/auth/auth.service';
import { FormBuilder, FormGroup, AbstractControl, FormControl, Validators } from '@angular/forms';
import { serialize } from 'class-transformer';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {

  public parametrPod = {Key: 'Podstawa', Value: ''};
  public parametrPolewa = {Key: 'Polewa', Value: ''};
  public parametrPosypka = {Key: 'Posypka', Value: ''};
  public parametrZdobienie = {Key: 'Zdobienie', Value: ''};
  public parametrOzdoba = {Key: 'Ozdoba', Value: ''};
  public parametrDodatek = {Key: 'Dodatek', Value: ''};

  public imgConfig: any;


  public podstawa: AbstractControl;
  public polewa: AbstractControl;
  public posypka: AbstractControl;
  public zdobienie: AbstractControl;
  public ozdoba: AbstractControl;
  public dodatek: AbstractControl;
  public imageForm: FormGroup;
  public parameter = {Key: '', Value: ''};
  public parametersTable: any[] = [];


  constructor(private fb: FormBuilder, private auth: AuthService, private http: HttpClient, private sanitizer: DomSanitizer) {
              }



  ngOnInit() {
  }


  initLoginForm() {
    this.imageForm = this.fb.group({
      podstawa: ['', Validators.required],
      polewa: ['', Validators.required],
      posypka: ['', Validators.required],
      zdobienie: ['', Validators.required],
      ozdoba: ['', Validators.required],
      dodatek: ['', Validators.required]
    });
    this.podstawa = this.imageForm.controls.podstawa;
    this.polewa = this.imageForm.controls.polewa;
    this.posypka = this.imageForm.controls.posypka;
    this.zdobienie = this.imageForm.controls.zdobienie;
    this.ozdoba = this.imageForm.controls.ozdoba;
    this.dodatek = this.imageForm.controls.dodatek;
  }

  public onSubmit(event: Event): void {

    event.stopPropagation();
    this.parametersTable = [];
    this.parametersTable.push(this.parametrPod);
    this.parametersTable.push(this.parametrOzdoba);
    this.parametersTable.push(this.parametrDodatek);
    this.parametersTable.push(this.parametrPolewa);
    this.parametersTable.push(this.parametrPosypka);
    this.parametersTable.push(this.parametrZdobienie);

    console.log('Param', this.parametersTable);

    this.getImage();
  }

  public onSelectPod(event: any) {
    this.parametrPod.Value = event;
  }
  public onSelectPol(event: any) {
    this.parametrPolewa.Value = event;
  }
  public onSelectPos(event: any) {
    this.parametrPosypka.Value = event;
  }

  public onSelectZdo(event: any) {
    this.parametrZdobienie.Value = event;
  }

  public onSelectOzd(event: any) {
    this.parametrOzdoba.Value = event;
  }
  public onSelectDod(event: any) {
    this.parametrDodatek.Value = event;
  }


  public getImage() {
    this.imgConfig = '../../../assets/img/loading.gif';
    const dane = serialize(this.parametersTable);
    const request = new HttpRequest('POST', 'api/ActiveForm/GetImage?width=400',
    dane, {responseType: 'blob'});

    this.http.request(request).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Response:
        const urlCreator = window.URL;
        this.imgConfig = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(event.body));
      }
    }, (err: any) => {
        if (err instanceof HttpErrorResponse) {
          console.log(err);
        }
      });
  }



}
