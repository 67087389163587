import { MaterialModule } from './../../../shared/material/material.module';
import { LabelComponent } from './../../../shared/components/form-controls/label/label.component';
import { DynamicControlDirective } from './../dynamic-control/dynamic-control.directive';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DynamicFormRoutingModule } from './dynamic-form-routing.module';
import { DynamicFormComponent } from './dynamic-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InputComponent } from 'src/app/shared/components/form-controls/input/input.component';
import { SelectComponent } from 'src/app/shared/components/form-controls/select/select.component';
import { CheckBoxComponent } from 'src/app/shared/components/form-controls/check-box/check-box.component';


@NgModule({
  declarations: [DynamicFormComponent, DynamicControlDirective],
  imports: [
    CommonModule,
    DynamicFormRoutingModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  entryComponents: [
    InputComponent,
    SelectComponent,
    CheckBoxComponent,
    LabelComponent
  ]
})
export class DynamicFormModule { }
