import { SaveForm } from './../../shared/models/save-form.model';
import { FormSet } from './../../shared/models/form-set.model';
import { ActiveFormType } from './../../shared/models/active-form.model';
import { ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { HtmlControl } from 'src/app/shared/models/html-control.model';
import { FormService } from 'src/app/shared/service/form.service';
import { DynamicFormComponent } from './dynamic-form/dynamic-form.component';
import { MatStepper } from '@angular/material';
import { Subject } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-initial-form',
  templateUrl: './initial-form.component.html',
  styleUrls: ['./initial-form.component.css']
})
export class InitialFormComponent implements OnInit {
  @ViewChild('headerForm', { read: ViewContainerRef, static: true }) entryHeader: ViewContainerRef;
  @ViewChild('moduleForm', { read: ViewContainerRef, static: true }) entryModule: ViewContainerRef;
  @ViewChild('footerForm', { read: ViewContainerRef, static: true }) entryFooter: ViewContainerRef;
  @ViewChild('stepper', { static: true }) private myStepper: MatStepper;


  public HtmlControls: HtmlControl[];
  public Form: FormGroup;
  public tabs = ['Pozycja 1'];
  public EndForm = false;

  private headerControls: HtmlControl[];
  private isNewTab: boolean;
  private formSets: FormSet[];
  private headerStep: string;
  private formGroupOfEachType: SaveForm[];
  private alert = new Subject<string>();
  public alertMessage: string;
  isLoading = true;

  selected = new FormControl(0);

  constructor(private formService: FormService, private resolver: ComponentFactoryResolver,
              private viewportScroller: ViewportScroller, private fb: FormBuilder, private router: Router) {
    this.Form = this.fb.group({});
    this.formSets = new Array<FormSet>();
    this.formGroupOfEachType = new Array<SaveForm>();
  }

  ngOnInit(): void {
    this.getStep();
    this.alert.subscribe(message => this.alertMessage = message);
  }

  public getStep() {
    this.formService.getFirstStep().pipe(tap(
      (technologyForm) => {
        this.headerStep = technologyForm.Step;
        this.HtmlControls = this.formService.mapControlsToHtml(technologyForm.Controlls, technologyForm.Step);
        this.createComponentForSpecificModule(technologyForm.Type);
        this.isLoading = false;
      }
    )).subscribe();
  }

  private createComponentForSpecificModule(moduleType: string) {
    const factory = this.resolver.resolveComponentFactory(DynamicFormComponent);
    let componentRef: ComponentRef<DynamicFormComponent>;

    switch (moduleType) {
      case ActiveFormType.Header:
        componentRef = this.entryHeader.createComponent(factory);
        break;

      case ActiveFormType.Position:
        this.managePositionsControls();
        this.addPositionToSaveForm();
        componentRef = this.entryModule.createComponent(factory);
        break;

      case ActiveFormType.Footer:
        if (this.myStepper.selectedIndex !== 2) {
          this.myStepper.next();
          this.Form = this.fb.group({});
        }

        componentRef = this.entryFooter.createComponent(factory);
        break;
    }

    componentRef.instance.HtmlControls = this.HtmlControls;
    componentRef.instance.Form = this.Form;
    componentRef.instance.MoveToNextStep.subscribe($event => {
      this.moveToNextStep($event);
    });
  }

  private managePositionsControls() {
    if (this.myStepper.selectedIndex !== 1) {
      this.myStepper.next();
      this.headerControls = this.HtmlControls;
      this.saveFormAndAddToArray(1);
    }

    if (!this.formSets[this.selected.value]) {
      this.addNewSetOfPositionsToArray();
    } else {
      this.addNewControlsToSet();
    }
  }

  private createNewSetPositionsComponent() {
    this.entryModule.clear();
    const factory = this.resolver.resolveComponentFactory(DynamicFormComponent);
    let componentRef: ComponentRef<DynamicFormComponent>;
    this.Form = this.fb.group({});
    this.addNewSetOfPositionsToArray();
    componentRef = this.entryModule.createComponent(factory);
    componentRef.instance.HtmlControls = this.headerControls;
    componentRef.instance.Form = this.Form;
    componentRef.instance.MoveToNextStep.subscribe($event => {
      this.moveToNextStep($event);
    });
  }

  private addNewSetOfPositionsToArray() {
    this.Form = this.fb.group({});
    const formSet = new FormSet();
    formSet.SetId = this.selected.value;
    formSet.SetForm = this.Form;
    formSet.SetControls.push(this.headerControls);
    formSet.CurrentStepForSet = this.headerStep;
    this.formSets.push(formSet);
  }

  private addNewControlsToSet() {
    const specificSetForm = this.formSets[this.selected.value];
    specificSetForm.SetId = this.selected.value;
    specificSetForm.SetForm = this.Form;
    specificSetForm.SetControls.push(this.HtmlControls);
    this.formSets.filter(x => x.SetId === this.selected.value).splice(0, 1, specificSetForm);
  }

  public loadModuleSetPositions(tabIndex: number) {
    if (!this.isNewTab) {
      this.selected.setValue(tabIndex);
      this.entryModule.clear();

      const factory = this.resolver.resolveComponentFactory(DynamicFormComponent);
      let componentRef: ComponentRef<DynamicFormComponent>;
      for (const selectedForm of this.formSets) {
        if (selectedForm.SetId === tabIndex) {
          for (const controlsForSet of selectedForm.SetControls) {
            componentRef = this.entryModule.createComponent(factory);
            componentRef.instance.HtmlControls = controlsForSet;
            componentRef.instance.Form = selectedForm.SetForm;
            componentRef.instance.MoveToNextStep.subscribe($event => {
              this.moveToNextStep($event);
            });
          }
        }
      }
    }
    this.isNewTab = false; // Nie hejtować komentarz pisałem późno. xD
    // Sterowanie tabami. Niestety funkcja ta się uruchamia gdy doda się nowy tab
    // gdy się ona wykonuje próbuje ona wziąć rzeczy które nie istnieją. Na chwilę obecną tylko na to wpadłem
    // Rozwiązałem go za pomocą isNewTab jeśli dodajemy nowy tab propercje ustawiamy na true
    // do funkcji wejdzie jednak się nie wykona a na sam koniec zmienna isNewtab ustawiana jest na false dzieki temu
    // mozliwe jest wczytanie modulu.
  }

  public addTab() {
    this.isNewTab = true;
    this.tabs.push('Pozycja ' + (this.tabs.length + 1));
    this.selected.setValue(this.tabs.length - 1);
    this.createNewSetPositionsComponent();
  }

  public moveToNextStep(form: FormGroup) {
    let sendStep: string;
    if (this.formSets[this.selected.value]) {
      sendStep = this.formSets[this.selected.value].CurrentStepForSet;
    } else {
      sendStep = this.headerStep;
    }
    console.log('Test Formy', form);
    this.formService.moveToNextStep(form, sendStep).pipe(tap(
      (technologyForm) => {
        if (technologyForm.Step !== '') {
          if (this.formSets[this.selected.value]) {
            this.formSets[this.selected.value].CurrentStepForSet = technologyForm.Step;
            this.HtmlControls = this.formService.mapControlsToHtml(technologyForm.Controlls, technologyForm.Step);
          } else {
            this.headerStep = technologyForm.Step;
            this.HtmlControls = this.formService.mapControlsToHtml(technologyForm.Controlls, technologyForm.Step);
          }
          this.createComponentForSpecificModule(technologyForm.Type);
        } else {
          this.EndForm = true;
        }
      }
    )).subscribe();
  }

  private saveFormAndAddToArray(typeId: number) {
    const saveForm = new SaveForm();
    saveForm.Form = this.Form;
    saveForm.Type = typeId;
    saveForm.Position = 0;
    this.formGroupOfEachType.push(saveForm);
  }

  private addPositionToSaveForm() {
    const whichTab = this.selected.value + 1;
    const saveForm = new SaveForm();
    if (this.formGroupOfEachType.filter(x => x.Position > 0).length > 0) {
      if (this.formGroupOfEachType.filter(x => x.Position === whichTab).length > 0) {
         saveForm.Position = whichTab;
         saveForm.Form = this.Form;
         saveForm.Type = 3;
         this.formGroupOfEachType.filter(x => x.Position === whichTab).splice(0, 1, saveForm);
      } else {
        saveForm.Position = whichTab;
        saveForm.Form = this.Form;
        saveForm.Type = 3;
        this.formGroupOfEachType.push(saveForm);
      }
    } else {
      saveForm.Position = whichTab;
      saveForm.Form = this.Form;
      saveForm.Type = 3;
      this.formGroupOfEachType.push(saveForm);
    }
  }

  public save() {
    this.saveFormAndAddToArray(5);
    this.formService.mergeControlsAndSave(this.formGroupOfEachType).subscribe(
      data => {
      this.alert.next('Zamówienie zostało zapisane, trwa przenoszenie do rejestru');
      this.viewportScroller.scrollToAnchor('alter');
      setTimeout(() => this.router.navigate([`/register`]) , 500);
      }
    );
  }
}
