import { Injectable } from '@angular/core';
import { ValidationService } from '../shared/utility/validation.service';
import { Router } from '@angular/router';
import { TmplAstBoundAttribute } from '@angular/compiler';
import { HttpHeaders, HttpRequest, HttpEvent, HttpEventType, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public token;

  constructor(public validationService: ValidationService, private router: Router,  private http: HttpClient) {

    this.token = localStorage.getItem('token');
  }

  goToHome() {
    this.router.navigate(['/home']);
  }

  public login(): void {
    const user = { status: 'loggedIn' };
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.router.navigate(['/home']);
  }

  // public getHeader() {
  //   const header: HttpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' +
  //     this.token).set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
  //   /*header.append('Authorization', 'Bearer ' + this.token);*/
  //   return header;
  // }

  // public getHeaderJson() {
  //   const header: HttpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' +
  //     this.token).set('Content-Type', 'application/json');
  //   /*header.append('Authorization', 'Bearer ' + this.token);*/
  //   return header;
  // }

  autoLogin() {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('grant_type', 'password');
    urlSearchParams.append('username', 'sszumska');
    urlSearchParams.append('password', 'q4hxnoyaa6');
    const body = urlSearchParams.toString();
    const request = new HttpRequest('POST', 'token', body);
    this.http.request(request).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Response:
          this.token = event.body.access_token;
          localStorage.setItem('token', event.body.access_token);
      }
    }, (err: any) => {
      });
  }

  public getUser(): boolean {

    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (currentUser) {
      console.log('User', currentUser);
      return true;
    } else {
      return false;
    }
  }

}
