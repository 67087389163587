import { HtmlControl } from './../../../models/html-control.model';
import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

  public Control: HtmlControl;
  public Group: FormGroup;

  constructor() { }

  ngOnInit(): void {
    console.log(this.Group.controls, this.Control);
  }

  onChange() {

  }

}
