import { Parameter } from './form-controlls.model';
export class ActiveForm {
  CurrentStep: string;
  PreviewParameters?: any[];
  Parameters: Parameter[] = new Array<Parameter>();
  ContractorNumber: string;
  Product: string;
  Iso639: string;
  IsCache?: boolean;
  Type?: ActiveFormType;
}

export enum ActiveFormType {
  Header = 'Header',
  ModuleHeader = 'ModuleHeader',
  Position = 'Position',
  ModuleFooter = 'ModuleFooter',
  Footer = 'Footer'
}
