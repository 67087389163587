import { MaterialModule } from './../material/material.module';
import { SelectComponent } from './form-controls/select/select.component';
import { InputComponent } from './form-controls/input/input.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckBoxComponent } from './form-controls/check-box/check-box.component';
import { LabelComponent } from './form-controls/label/label.component';


export const COMPONENTS = [
  InputComponent,
  SelectComponent,
  CheckBoxComponent,
  LabelComponent
];
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule
   ],
   declarations: COMPONENTS,
   exports: COMPONENTS
})

export class ComponentsModule {}
