import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderService } from 'src/app/shared/service/header.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router, private headerService: HeaderService) {
    this.headerService.showOrHideHeader.next(true);
  }

  ngOnInit(): void {
  }

  routeTo(route: string) {
    this.router.navigate([`/${route}`]);
  }

}
