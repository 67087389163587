import { MaterialModule } from './../../shared/material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormRoutingModule } from './form-routing.module';
import { FormComponent } from './form.component';
import { ComponentsModule } from 'src/app/shared/components';
import { AuthModule } from 'src/app/auth/auth.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [FormComponent],
  imports: [
    CommonModule,
    FormRoutingModule,
    ComponentsModule,
    AuthModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialModule
  ]
})
export class FormModule { }
