import { Component } from '@angular/core';
import { HeaderService } from './shared/service/header.service';

@Component({
  selector: 'app-root',
  template: `
  <div class="d-flex w-100 mx-auto flex-column" style="min-height: 100vh">
    <app-header *ngIf="this.showHeader" style="margin-bottom: 46.5px"></app-header>
    <router-outlet></router-outlet>
    <app-footer *ngIf="this.showHeader" class="mt-auto"></app-footer>
  </div>
  `,
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'TarkusB2BAngular';

  public showHeader: boolean;

  constructor(private headerService: HeaderService) {
    this.headerService.showOrHideHeader.subscribe((x) => { if (!x) {this.showHeader = false; } else { this.showHeader = true; }});
  }
}
