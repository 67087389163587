import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';


import { AuthRoutingModule } from './auth-routing.module';
import { AuthApiClientService } from './auth-api-client.service';
import { AuthService } from './auth.service';
import { LoginComponent } from './login/login.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ComponentsModule } from '../shared/components';
import { InterceptorService } from '../shared/async-services/http/http.interceptor';

@NgModule({
  declarations: [LoginComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule
  ],
  providers: [AuthApiClientService, AuthService,    {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
    }]
})
export class AuthModule { }
