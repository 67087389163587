import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { Order } from 'src/app/shared/models/order.model';

import { tap } from 'rxjs/operators';
import { OrdersService } from './orders.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements AfterViewInit {

  displayedColumns: string[] = ['select', 'B2BId', 'Product', 'System', 'Quantity', 'ValueNetto', 'ContractorName', 'ClientNumber',
    'Description', 'OrderDate', 'OrderStatusName', 'Specification', 'Valuation', 'Edit', 'Copy', 'Remove'];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  selection = new SelectionModel<Order>(true, []);
  isLoading = true;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private ordersService: OrdersService, private modalService: NgbModal) {
    ordersService.getOrders().pipe(tap(data => {this.dataSource.data = data;  this.isLoading = false; })).subscribe();
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }


  removeOrder(id: number) {
    this.ordersService.removeOrder(id);
    this.refreshData();
  }

  refreshData() {
    this.ordersService.getOrders().pipe(tap(data => {
      this.dataSource.data = data;
    })).subscribe();
  }

  openModal(content: any, id: number) {
    this.modalService.open(content).result.then((result) => {
      if (result === 'Tak') {
        this.removeOrder(id);
      }
    });
  }
}
