import { LabelComponent } from './../../../shared/components/form-controls/label/label.component';

import { OnInit, Input, ComponentFactoryResolver, ViewContainerRef, Directive, ComponentRef, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CheckBoxComponent } from 'src/app/shared/components/form-controls/check-box/check-box.component';
import { InputComponent } from 'src/app/shared/components/form-controls/input/input.component';
import { SelectComponent } from 'src/app/shared/components/form-controls/select/select.component';
import { IControlComponent } from 'src/app/shared/interfaces/control-component.interface';
import { HtmlControl } from 'src/app/shared/models/html-control.model';


const componentMapper = {
  Text: InputComponent,
  Select: SelectComponent,
  Check: CheckBoxComponent,
  Label: LabelComponent,
  Number: InputComponent
};

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[dynamic-control]'
})


export class DynamicControlDirective implements OnInit {
  @Input() field: HtmlControl;
  @Input() group: FormGroup;

  constructor(
    private resolver: ComponentFactoryResolver,
    private container: ViewContainerRef,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    if (componentMapper[this.field.componentName]) {
    const factory = this.resolver.resolveComponentFactory(
      componentMapper[this.field.componentName]
    );

    const componentRef = this.container.createComponent(factory);

    this.renderer.addClass(componentRef.location.nativeElement, 'col-' + this.field.width);

    const component = componentRef.instance as IControlComponent;
    component.Control = this.field;
    component.Group = this.group;
    }
  }
}
