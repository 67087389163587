import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { FormComponent } from './features-modules/form/form.component';
import { FormsComponent } from './features-modules/forms/forms.component';
import { HomeComponent } from './features-modules/home/home.component';
import { DynamicFormComponent } from './features-modules/initial-form/dynamic-form/dynamic-form.component';
import { InitialFormComponent } from './features-modules/initial-form/initial-form.component';

import { OrdersComponent } from './features-modules/orders/orders.component';

const routes: Routes = [
  {
    path: '', component: LoginComponent, pathMatch: 'full'
  },
  {
    path: 'dynamic-form', component: DynamicFormComponent, pathMatch: 'full'
  },
  {
    path: 'initial-form', component: InitialFormComponent, pathMatch: 'full'
  },
  {
    path: 'form', component: FormComponent, pathMatch: 'full'
  },
  {
    path: 'offer', component: FormsComponent, pathMatch: 'full'
  },
  {
    path: 'home', component: HomeComponent, pathMatch: 'full'
  },
  {
    path: 'register', component: OrdersComponent, pathMatch: 'full'
  },
  {
  path: '',
  children: [
    {
      path: '',
      loadChildren: './features-modules/form/form.module#FormModule'
    }
  ]
},
  { path: 'forms', loadChildren: () => import('./features-modules/forms/forms.module').then(m => m.FormsModule) },
  { path: 'dynamic-form', loadChildren: () => import('./features-modules/initial-form/dynamic-form/dynamic-form.module').then(m => m.DynamicFormModule) },
  { path: 'initial-form', loadChildren: () => import('./features-modules/initial-form/initial-form.module').then(m => m.InitialFormModule) }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
