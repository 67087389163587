import { HtmlControl } from './../../../shared/models/html-control.model';
import { Component, OnInit, Output, EventEmitter, ViewChild, ViewContainerRef } from '@angular/core';
import { tap } from 'rxjs/operators';
import { FormService } from 'src/app/shared/service/form.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.css']
})
export class DynamicFormComponent implements OnInit {
  @Output() MoveToNextStep = new EventEmitter<FormGroup>();

  @Input() HtmlControls: HtmlControl[];
  @Input() Form: FormGroup;

  public currentStep: string;
  // moveToNextStep: BehaviorSubject<FormGroup>;


  constructor(private fb: FormBuilder) {
   // this.moveToNextStep = new BehaviorSubject<FormGroup>(this.Form);
  }

  ngOnInit(): void {
    this.createControls();

  }

  public createControls() {
    for (const controlConfig of this.HtmlControls) {
      // if (!this.technologyVersion) {
      //   this.technologyVersion = controlConfig.technologyVersion;
      // }
      const control = this.fb.control(
        controlConfig.value,
        this.bindValidations(controlConfig.validations || [])
      );
      this.Form.addControl(controlConfig.name, control);
      this.currentStep = controlConfig.currentStep;
    }
  }

  bindValidations(validations: any) {
    if (validations.length > 0) {
      const validList = [];
      validations.forEach(valid => {validList.push(valid.validator);
    });
      return Validators.compose(validList);
    }
    return null;
  }


  public onSubmit(form: FormGroup, event: any) {
    // this.moveToNextStep.next(this.Form);
    if (form.valid) {
      this.MoveToNextStep.emit(form);
    } else {
      this.validateAllFormFields(form);
    }
  }


  validateAllFormFields(formGroup: FormGroup)  {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      control.markAsTouched({ onlySelf: true });
    });
  }

}
