import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpEventType, HttpErrorResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class InterceptorService implements HttpInterceptor {

   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('token');
    let modifiedReq: HttpRequest<any> = null;
    if (req.method === 'POST') {
      modifiedReq = req.clone( {url: `https://api.tarkus.pl/${req.url}`,
      headers: req.headers.set('Authorization', 'Bearer ' + token).set('Content-Type', 'application/json')
      });
    } else if (req.method === 'GET' || req.url === 'token') {
      modifiedReq = req.clone( {url: `https://api.tarkus.pl/${req.url}`,
      responseType: req.responseType,
      headers: req.headers.set('Authorization', 'Bearer ' + token).set('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8')
      });
    }
    return next.handle(modifiedReq).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          errorMessage = `Error: ${error.error.message}`;
        } else {
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
      }));
   }

}
