import { SaveForm } from './../models/save-form.model';
import { ActiveForm } from './../models/active-form.model';
import { FormGroup } from '@angular/forms';
import { HtmlControl } from './../models/html-control.model';
import { TechnologyControl, Parameter, FormControls } from './../models/form-controlls.model';
import { FormControlMapper } from './../mappers/form-control.mapper';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class FormService {

  private formControlMapper: FormControlMapper;

  constructor(private httpClient: HttpClient) {
    this.formControlMapper = new FormControlMapper();
  }

  public getFirstStep() {
    const data = {
      ContractorNumber: null,
      Product: 'FASALUPROF',
      Iso639: 'POL'
    };
    return this.httpClient.post<FormControls>('api/ActiveForm/GetStep', data);
  }

  public moveToNextStep(form: FormGroup, step: string) {
    const activeForm: ActiveForm = new ActiveForm();
    activeForm.ContractorNumber = '00000';
    activeForm.Product = 'FASALUPROF';
    activeForm.Iso639 = 'POL';
    activeForm.CurrentStep = step;
    activeForm.IsCache = true;
    console.log('FormElementControls', form.controls);

    Object.keys(form.controls).forEach(key => {

      activeForm.Parameters.push({Key: key, Value: form.controls[key].value});
    });

    console.log('Param', activeForm.Parameters);

    return this.httpClient.post<FormControls>('api/ActiveForm/GetStep', activeForm);
  }

  public mergeControlsAndSave(saveForm: SaveForm[]) {
    console.log('saveForm', saveForm);
    const activeForm: ActiveForm = new ActiveForm();
    activeForm.ContractorNumber = '00000';
    activeForm.Product = 'FASALUPROF';
    activeForm.Iso639 = 'POL';
    activeForm.IsCache = true;

    saveForm.forEach(element => {
      console.log('FormElementControls', element.Form.controls);

      Object.keys(element.Form.controls).forEach(key => {
        if (key === 'InfoStopkaZestawu' || key === 'InfoStopka') {
        activeForm.Parameters.push({Key: key, Value: element.Form.controls[key].value,
          Position: element.Position, Module: element.Module , Type: element.Type, IsPrint: false});
        } else {
          activeForm.Parameters.push({Key: key, Value: element.Form.controls[key].value,
            Position: element.Position, Module: element.Module , Type: element.Type, IsPrint: true});
        }
      });
    });

    return this.httpClient.post<any>('api/ActiveForm/AddorUpdateActiveFormOrder', activeForm);

  }

  public mapControlsToHtml(technologyControls: TechnologyControl[], step: string) {
    const controlConfigList = new Array<HtmlControl>();
    technologyControls.forEach(technologyControl =>  {
      const control = this.formControlMapper.mapToHtml(technologyControl, step);
      controlConfigList.push(control);
    });
    return controlConfigList;
  }
}
