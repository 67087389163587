import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Order } from 'src/app/shared/models/order.model';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private httpClient: HttpClient) { }

  public getOrders(): Observable<Order[]> {
    return this.httpClient.post<Order[]>(`api/ActiveForm/GetOrders?status=1`, '');
  }

  public removeOrder(id: number) {
    this.httpClient.post<any>(`api/ActiveForm/RemoveOrder`, `{"Id": ${id}}`).subscribe();
  }
}
