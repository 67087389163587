import { Parameter } from './form-controlls.model';

export class HtmlControl {
  name: string;
  label: string;
  inputType: string;
  value?: any;
  componentName: string;
  options?: Parameter[];
  validations?: Validator[];
  technologyVersion?: number;
  width: number;
  isPrint: boolean;
  currentStep: string;
}

class Validator {
  name: string;
  validator: any;
  message: string;
}
