import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  public showOrHideHeader: BehaviorSubject<boolean>;
  public showOrHide$: Observable<boolean>;

  constructor() {
    this.showOrHideHeader = new BehaviorSubject<boolean>(true);
    this.showOrHide$ = this.showOrHideHeader.asObservable();
  }

}
