import { MaterialModule } from './../../shared/material/material.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InitialFormRoutingModule } from './initial-form-routing.module';
import { InitialFormComponent } from './initial-form.component';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [InitialFormComponent],
  imports: [
    CommonModule,
    InitialFormRoutingModule,
    MaterialModule,
    NgbAlertModule
  ]
})
export class InitialFormModule { }
